/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { getOr, isEmpty } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Button,
  Grid,
  Box,
  CircularProgress,
} from '@material-ui/core';

import { alert, checkout, businesses } from '../../../state';
import PoweredByClover from '../../../images/poweredByClover.png';

const cloverUrl = process.env.GATSBY_CLOVER_SCRIPT;

const hasCloverScript = () =>
  document.querySelector('script#clover-sdk') !== null;

const hideCloverFooter = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  if (cloverFooter) {
    cloverFooter.style.display = 'none';
  }
};

const appendCloverScript = (setScriptLoaded) => {
  const script = document.createElement('script');
  script.id = 'clover-sdk';
  script.type = 'text/javascript';
  script.src = cloverUrl;
  script.onload = () => {
    setScriptLoaded(true);
  };
  script.onerror = () => console.error('Failed to Load Clover script!');
  document.body.appendChild(script);
};

const unmountClover = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  const cloverScript = document.querySelector('script#clover-sdk');
  const allCloverFrames = document.querySelectorAll(
    'iframe[title^="INTERMEDIATE"]'
  );
  if (cloverFooter) {
    cloverFooter.remove();
  }
  if (cloverScript) {
    cloverScript.remove();
  }
  if (!isEmpty(allCloverFrames)) {
    Array.from(allCloverFrames).forEach((frame) => frame.parentNode.remove());
  }
};

const unmountFields = () => {
  const allFrames = document.querySelectorAll('#clover-form iframe');
  if (!isEmpty(allFrames)) {
    Array.from(allFrames).forEach((frame) =>
      frame.parentNode.removeChild(frame)
    );
  }
};

let clover;

// eslint-disable-next-line react/prop-types
const CheckoutClover = ({ acceptTerms, onSubmit, formData, children }) => {
  const { t } = useTranslation();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [cloverIsReady, setCloverIsReady] = useState(false);
  const dispatch = useDispatch();
  const { id: businessId, currency } = useSelector(
    businesses.selectors.selectBusiness
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const [errors, setErrors] = useState({
    CARD_NUMBER: '',
    CARD_DATE: '',
    CARD_CVV: '',
    CARD_POSTAL_CODE: '',
  });
  const { customerPaysAmount, totalAmount } = formData;

  const cardNumberListener = (payload) => {
    const error = getOr('', ['CARD_NUMBER', 'error'], payload);
    if (errors) {
      setErrors({ ...errors, CARD_NUMBER: error });
    }
  };
  const cardDateListener = (event) => {
    const error = getOr('', ['CARD_DATE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_DATE: error });
    }
  };
  const cardCvvListener = (event) => {
    const error = getOr('', ['CARD_CVV', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_CVV: error });
    }
  };
  const cardPostalCodeListener = (event) => {
    const error = getOr('', ['CARD_POSTAL_CODE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_POSTAL_CODE: error });
    }
  };
  const cannotProcessPayments = () => {
    openAlert({
      message: t('weCannotProcessPaymentsPleaseTryLater'),
      severity: 'error',
    });
  };

  const submit = async () => {
    setIsSubmitting(true);
    await clover.createToken().then(async (result) => {
      console.log(onSubmit, 222);
      if (result.errors) {
        setErrors(result.errors);
        return null;
      }
      await onSubmit(result);
      setIsSubmitting(false);
      return null;
    });
  };

  useEffect(() => {
    if (!hasCloverScript()) {
      appendCloverScript(setScriptLoaded);
    }
    return () => {
      if (hasCloverScript()) {
        unmountClover();
        setScriptLoaded(false);
        setCloverIsReady(false);
      }
    };
  }, []);

  const getCloverIframeToken = (payload) =>
    dispatch(checkout.actions.getCloverIframeToken(payload));

  useEffect(() => {
    if (scriptLoaded) {
      const createCloverInstance = async () => {
        if (businessId) {
          const res = await getCloverIframeToken(businessId);
          if (res.payload?.token) {
            const CloverInstance = window.Clover;
            clover = new CloverInstance(res.payload?.token);
            setCloverIsReady(true);
          } else {
            cannotProcessPayments();
          }
        }
      };
      createCloverInstance();
    }
  }, [scriptLoaded, businessId]);

  useEffect(() => {
    if (cloverIsReady) {
      const elements = clover.elements();
      const cardNumber = elements.create('CARD_NUMBER');
      const cardDate = elements.create('CARD_DATE');
      const cardCvv = elements.create('CARD_CVV');
      const cardPostalCode = elements.create('CARD_POSTAL_CODE');
      cardNumber.mount('#card-number');
      cardDate.mount('#card-date');
      cardCvv.mount('#card-cvv');
      cardPostalCode.mount('#postal-code');
      cardNumber.addEventListener('blur', cardNumberListener);
      cardDate.addEventListener('blur', cardDateListener);
      cardCvv.addEventListener('blur', cardCvvListener);
      cardPostalCode.addEventListener('blur', cardPostalCodeListener);
      hideCloverFooter();
      return () => {
        cardNumber.removeEventListener('blur', cardNumberListener);
        cardDate.removeEventListener('blur', cardDateListener);
        cardCvv.removeEventListener('blur', cardCvvListener);
        cardPostalCode.removeEventListener('blur', cardPostalCodeListener);
        unmountFields();
      };
    }
    return undefined;
  }, [cloverIsReady]);

  if (!cloverIsReady) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Box pb={2}>
            <Grid container item xs={12}>
              <TextField
                id="card-number"
                label={t('cardNumber')}
                name="ccnumber"
                variant="outlined"
                error={!!errors.CARD_NUMBER}
                helperText={errors.CARD_NUMBER}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: 'div',
                }}
              />
            </Grid>
          </Box>
          <Box pb={2}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={4}>
                <TextField
                  id="card-date"
                  label={t('expirationDate')}
                  name="ccexp"
                  variant="outlined"
                  fullWidth
                  error={!!errors.CARD_DATE}
                  helperText={errors.CARD_DATE}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: 'div',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="card-cvv"
                  label={t('cvc')}
                  name="cvc"
                  variant="outlined"
                  fullWidth
                  error={!!errors.CARD_CVV}
                  helperText={errors.CARD_CVV}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: 'div',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="postal-code"
                  label={t('postalCode')}
                  name="postalCode"
                  variant="outlined"
                  fullWidth
                  error={!!errors.CARD_POSTAL_CODE}
                  helperText={errors.CARD_POSTAL_CODE}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: 'div',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {children}
        <Grid item xs={12}>
          <Button
            disabled={isSubmitting || !acceptTerms}
            id="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={submit}
          >
            {isSubmitting ? (
              <CircularProgress size={22} />
            ) : (
              t('pay', {
                amount: customerPaysAmount || totalAmount,
                currency: formData.currency || currency,
              })
            )}
          </Button>
        </Grid>
      </Grid>
      <Box py={2} align="center">
        <img
          src={PoweredByClover}
          alt="Powered By Clover"
          style={{ maxHeight: 20 }}
        />
      </Box>
    </>
  );
};

export default CheckoutClover;
