import * as Yup from 'yup';
import i18n from 'i18next';

const phoneRegExp = /^\d{8,}$/;

const MembershipRegisterSchema = (showAddressInput) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    lastName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('required')),
    phone: Yup.string(),
    // .required(i18n.t('required')),
    localPhone: Yup.string()
      .matches(/\d{8,}/, 'Phone number is not valid')
      .required(i18n.t('required')),
    countryCode: Yup.string().required(i18n.t('required')),
    isGift: Yup.boolean(),
    recipientFirstName: Yup.string().when('isGift', {
      is: true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
    }),
    recipientLastName: Yup.string().when('isGift', {
      is: true,
      then: Yup.string()
        .min(2, i18n.t('tooShort'))
        .max(50, i18n.t('tooLong'))
        .required(i18n.t('required')),
    }),
    recipientEmail: Yup.string().when('isGift', {
      is: true,
      then: Yup.string()
        .email(i18n.t('invalidEmail'))
        .required(i18n.t('required')),
    }),
    recipientLocalPhone: Yup.string().when('isGift', {
      is: true,
      then: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required(i18n.t('required')),
    }),
    recipientPhone: Yup.string().when('isGift', {
      is: true,
      then: Yup.string().required(i18n.t('required')),
    }),
    recipientCountryCode: Yup.string().when('isGift', {
      is: true,
      then: Yup.string().required(i18n.t('required')),
    }),
    address: showAddressInput
      ? Yup.string()
          .min(2, i18n.t('tooShort'))
          .max(100, i18n.t('tooLong'))
          .required(i18n.t('required'))
      : Yup.string().nullable(),
    city: showAddressInput
      ? Yup.string()
          .min(2, i18n.t('tooShort'))
          .max(50, i18n.t('tooLong'))
          .required(i18n.t('required'))
      : Yup.string().nullable(),
    zipcode: showAddressInput
      ? Yup.string()
          .min(2, i18n.t('tooShort'))
          .max(50, i18n.t('tooLong'))
          .required(i18n.t('required'))
      : Yup.string().nullable(),
    state: showAddressInput
      ? Yup.string().min(2, i18n.t('tooShort')).required(i18n.t('required'))
      : Yup.string().nullable(),
    birthMonth: Yup.number().when('hasLoyalty', {
      is: (hasLoyalty) => hasLoyalty,
      then: Yup.number().required(i18n.t('required')),
    }),
    questions: Yup.array().when('customQuestion', {
      is: true,
      then: Yup.array().of(
        Yup.object({
          note: Yup.string()
            .min(1, i18n.t('required'))
            .required(i18n.t('required')),
        })
      ),
    }),
    renewalRecipientType: Yup.number()
      .nullable()
      .when('isGift', {
        is: true,
        then: Yup.number().required(i18n.t('required')),
      }),
    planId: Yup.string().required(i18n.t('required')),
    code: Yup.string().when('numberType', {
      is: (numberType) => numberType === 2,
      then: Yup.string()
        // .min(6, 'Member number must be at least 6 character')
        .required(i18n.t('required')),
      // eslint-disable-next-line func-names
      // .test(async function (value) {
      //   const error = await validateMembershipCode(value, this);
      //   return error;
      // }),
    }),
  });
};

export default MembershipRegisterSchema;
