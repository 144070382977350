import * as Yup from 'yup';
import i18n from 'i18next';

const UserInfoSchema = (ticketSetup) =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    lastName: Yup.string()
      .min(2, i18n.t('tooShort'))
      .max(50, i18n.t('tooLong'))
      .required(i18n.t('required')),
    email: Yup.string()
      .email(i18n.t('invalidEmail'))
      .required(i18n.t('required')),
    phone: Yup.number().required(i18n.t('required')),
    totalTicket: Yup.number()
      .required(i18n.t('ticket.totalTicketRequired'))
      .min(0.001, i18n.t('ticket.totalTicketRequired')),

    address: ticketSetup?.showAddressFields
      ? Yup.string().required(i18n.t('required'))
      : Yup.string(),
    city: ticketSetup?.showAddressFields
      ? Yup.string().required(i18n.t('required'))
      : Yup.string(),
    state: ticketSetup?.showAddressFields
      ? Yup.string().required(i18n.t('required'))
      : Yup.string(),
    zipcode: ticketSetup?.showAddressFields
      ? Yup.string().required(i18n.t('required'))
      : Yup.string(),
  });

export default UserInfoSchema;
