import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Divider } from '@material-ui/core';
import { useParams } from '@reach/router';
import moment from 'moment';
import _ from 'lodash';
import { businesses, tickets } from '../../../state';

import SEO from '../../seo';
import { BusinessHeader, BusinessFooter } from '../../layouts';
import Events from './Events';

const TicketEventList = () => {
  const { i18n } = useTranslation();
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const [eventsList, setEventsList] = useState([]);
  const business = useSelector(businesses.selectors.selectBusiness);
  const events = useSelector(tickets.selectors.selectTicketEvents);
  const ticketSetup = useSelector(tickets.selectors.selectTicketSetup);
  const groupByStartDate = () => {
    const groupedEvents = _.groupBy(events, (event) =>
      moment(event.startTime).format('YYYY-MM')
    );
    const sortedKeys = _.orderBy(
      Object.keys(groupedEvents),
      [(date) => moment(date)],
      ['asc']
    );
    const sortedGroupedEvents = _.fromPairs(
      sortedKeys.map((key) => [
        key,
        groupedEvents[key].sort((a, b) => {
          return new Date(a.startTime) - new Date(b.startTime);
        }),
      ])
    );
    console.log('sortedGroupedEvents', sortedGroupedEvents);
    return sortedGroupedEvents;
  };

  useEffect(() => {
    dispatch(businesses.actions.fetchMerchant(businessId));
    dispatch(tickets.actions.getListEvents(businessId));
    dispatch(tickets.actions.getTicketSetup(businessId));
  }, []);

  useEffect(() => {
    const prepareEvents = groupByStartDate(events);
    setEventsList(prepareEvents);
  }, [events]);

  useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { name, logoUrl, website, brandColors } = business;
  return (
    <>
      <SEO title={`${name}`} />
      <BusinessHeader
        logoUrl={logoUrl}
        merchantUrl={website}
        merchantPintunaUrl={website}
        bgcolor={business.brandColors?.headerColor || '#eee'}
      />
      <Divider />
      <Container
        maxWidth="lg"
        disableGutters
        bgcolor={business.brandColors?.backgroundColor || '#eee'}
        style={{ minHeight: 'calc(100vh - 133px)' }}
      >
        <Events
          events={eventsList}
          businessId={businessId}
          brandColors={brandColors}
          ticketSetup={ticketSetup}
        />
      </Container>
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </>
  );
};

export default TicketEventList;
