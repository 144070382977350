/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

import { Skeleton } from '@material-ui/lab';

import { useParams } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { alert, businesses, loyalties } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';
import { CustomImgCard } from '../../custom';
import LoyaltyRedeemSchema from './LoyaltyRedeemSchema';
import MobileShop from '../../../images/icons8-mobile-shop-coins-50.png';
import Stars from '../../../images/icons8-multiple-stars-24.png';

const useStyles = makeStyles(() => ({
  rightSpan: {
    float: 'right',
    textAlign: 'right',
    minWidth: 120,
    position: 'relative',
    '&:before': {
      content: '"="',
      position: 'absolute',
      left: -10,
    },
  },
  loyaltyText: {
    color: 'white !important',
    fontWeight: 'bold',
    fontSize: 18,
  },
}));
const LoyaltyRedeemForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const { t } = useTranslation();
  const loyaltyProgram = useSelector(loyalties.selectors.selectProgram);
  const isLoading = useSelector(loyalties.selectors.selectIsLoading);
  const formikRef = useRef();
  const { id, code } = useParams();

  const [isChecking, setIsChecking] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [storeCredit, setStoreCredit] = useState({});
  const business = useSelector(businesses.selectors.selectBusiness);
  const currency = business.currency || 'USD';
  const [loyaltyData, setLoyalty] = useState({});
  const checkRedemption = async () => {
    await setIsChecking(true);
    const res = await dispatch(
      loyalties.actions.getLoyaltyDetail({
        code: formikRef.current.values.code,
        id,
        redeemPoint: formikRef.current.values.redeemPoint,
      })
    );
    await setIsChecking(false);
    if (res.payload) {
      if (!res.error) {
        setLoyalty(res.payload);
        formikRef.current.setFieldValue(
          'redeemPoint',
          res.payload?.redeemPoint
        );
      } else {
        openAlert({
          message: res.payload?.message || 'Something went wrong...',
          severity: 'error',
        });
        setLoyalty({});
      }
    }
  };

  useEffect(async () => {
    if (code) {
      formikRef.current.setFieldValue('code', code);
      const res = await dispatch(
        loyalties.actions.getLoyaltyDetail({ code, id })
      );
      setLoyalty(res.payload);
    } else {
      setLoyalty({});
    }
  }, [code]);

  const closeDialog = () => {
    setShowDialog(false);
    dispatch(loyalties.actions.getLoyaltyDetail({ code, id }));
  };

  const onkeyPress = (e) => {
    if (e.keyCode === 13) {
      checkRedemption();
    }
  };
  const formatPont = (num) => {
    // eslint-disable-next-line no-nested-ternary
    return num ? (Number.isInteger(num) ? num.toString() : num.toFixed(2)) : '';
  };

  // eslint-disable-next-line react/prop-types
  const RightSpan = ({ text, style }) => {
    return (
      <span className={classes.rightSpan} style={style}>
        {text}
      </span>
    );
  };

  return (
    <Box
      borderRadius={20}
      border="1px solid #ccc"
      p={4}
      mb={4}
      bgcolor={loyaltyProgram.brandColors?.formColor || '#fff'}
    >
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <Box width={270} alignItems="center" margin="auto">
              <CustomImgCard
                isLoading={isLoading}
                name={loyaltyProgram.businessName}
                imageUrl={loyaltyProgram.imageUrl}
              />
            </Box>
            <Box py={1}>
              <Typography variant="h5" align="center">
                {loyaltyProgram.programName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Box>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box>
          ) : (
            <Formik
              innerRef={formikRef}
              validationSchema={LoyaltyRedeemSchema}
              initialValues={{
                id: loyaltyProgram.id,
                code,
                redeemPoint: 0,
              }}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true);
                const response = await dispatch(
                  loyalties.actions.redeem({
                    ...values,
                  })
                );
                if (response.error) {
                  openAlert({
                    message:
                      response.error?.message ||
                      t('unableCompleteRegistration'),
                    severity: 'error',
                  });
                } else if (response.payload) {
                  openAlert({
                    message: t('loyalty.successRedeemed'),
                    severity: 'success',
                  });
                  setShowDialog(true);
                  setStoreCredit(response.payload);
                }
                actions.setSubmitting(false);
              }}
            >
              {({ handleSubmit, isSubmitting, values }) => {
                return (
                  <Form margin="dense" size="small">
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12} md={10} spacing={2}>
                        <Box
                          border={1}
                          borderColor="primary.dark"
                          style={{
                            position: 'relative',
                            overflow: 'visible',
                            marginTop: 20,
                          }}
                        >
                          <CardContent>
                            {/* <Typography */}
                            {/*  style={{ */}
                            {/*    position: 'absolute', */}
                            {/*    top: -15, */}
                            {/*    left: 30, */}
                            {/*    background: '#fff', */}
                            {/*    padding: '0 10px', */}
                            {/*  }} */}
                            {/*  mt={2} */}
                            {/* > */}
                            {/*  Loyalty Program */}
                            {/* </Typography> */}
                            <RightSpan text={loyaltyProgram.requiredAmount} />
                            <Typography style={{ width: 260 }}>
                              {t('loyalty.pointsNeededToEarnAReward')}
                            </Typography>

                            <Typography>
                              {t('loyalty.rewardStoreCredit')}
                              <RightSpan
                                text={t('cardValue', {
                                  amount: loyaltyProgram.storeCreditAmount,
                                  formatParams: {
                                    amount: { currency },
                                  },
                                })}
                              />
                            </Typography>
                          </CardContent>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <Box pt={2}>
                          <Grid
                            container
                            direction="row"
                            // justifyContent="space-around"
                            alignItems="center"
                            spacing={3}
                            style={{
                              background: '#000',
                              color: '#fff',
                              marginTop: 5,
                            }}
                          >
                            <Grid
                              item
                              container
                              alignItems="center"
                              alignContent="center"
                              justifyContent="space-around"
                            >
                              <Grid item xs={8}>
                                <Typography className={classes.loyaltyText}>
                                  {`${
                                    code
                                      ? `${t('loyalty.yourLoyalty')}`
                                      : t('loyalty.enterYourLoyalty')
                                  }`}
                                </Typography>
                              </Grid>
                              {!code ? (
                                <Grid item xs={4}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    capitalize
                                    fullWidth
                                    required
                                    disabled={code}
                                    style={{
                                      display: code ? 'none' : 'block',
                                      backgroundColor: '#fff',
                                      borderRadius: 4,
                                    }}
                                    name="code"
                                    variant="outlined"
                                    onKeyUp={onkeyPress}
                                  />
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  container
                                  xs={4}
                                  justifyContent="space-between"
                                  direction="row"
                                >
                                  <Typography className={classes.loyaltyText}>
                                    =
                                  </Typography>
                                  <Typography className={classes.loyaltyText}>
                                    {code || ''}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>

                            {/* <Grid item> */}
                            {/*  <Button */}
                            {/*    onClick={checkRedemption} */}
                            {/*    size="small" */}
                            {/*    disabled={isSubmitting} */}
                            {/*    variant="contained" */}
                            {/*    color="primary" */}
                            {/*  > */}
                            {/*    Check */}
                            {/*  </Button> */}
                            {/* </Grid> */}
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <Box mt={2} mr={2}>
                          <Typography style={{ marginTop: 15 }}>
                            <img
                              src={Stars}
                              width={20}
                              alt=""
                              style={{ marginRight: 5 }}
                            />
                            {t('loyalty.yourCurrentPointsBalance')}{' '}
                            <RightSpan text={formatPont(loyaltyData.point)} />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <Typography
                          style={{
                            marginLeft: 25,
                            marginTop: 15,
                            verticalAlign: 'middle',
                            lineHeight: '40px',
                          }}
                        >
                          {t('loyalty.pointToConvert')}*
                          <RightSpan
                            style={{ width: 136 }}
                            text={
                              <>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  size="small"
                                  style={{ width: 60, marginLeft: 5 }}
                                  capitalize
                                  required
                                  name="redeemPoint"
                                  variant="outlined"
                                />
                                <Button
                                  onClick={checkRedemption}
                                  size="small"
                                  disabled={isSubmitting}
                                  variant="contained"
                                  color="primary"
                                  style={{ width: 60, marginLeft: 5 }}
                                >
                                  {isChecking ? (
                                    <CircularProgress size={24} />
                                  ) : (
                                    'Check'
                                  )}
                                </Button>
                              </>
                            }
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <Box mt={2} mr={2}>
                          <Typography style={{ marginTop: 15 }}>
                            <img
                              src={MobileShop}
                              width={20}
                              alt=""
                              style={{ marginRight: 5 }}
                            />
                            {t('loyalty.inStoreCredit')}{' '}
                            <RightSpan
                              text={
                                loyaltyData.rewardStoreCredit
                                  ? t('cardValue', {
                                      amount: loyaltyData.rewardStoreCredit,
                                      formatParams: {
                                        amount: { currency },
                                      },
                                    })
                                  : ''
                              }
                            />
                          </Typography>
                          <Typography style={{ marginLeft: 25, marginTop: 15 }}>
                            {t('loyalty.pointBalanceWillBe')}{' '}
                            <RightSpan
                              text={formatPont(loyaltyData?.remainingPoint)}
                            />
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box my={2}>
                      <Grid container justifyContent="center">
                        <Grid
                          container
                          item
                          md={10}
                          xs={12}
                          alignItems="center"
                        >
                          <Button
                            onClick={handleSubmit}
                            size="large"
                            disabled={
                              isSubmitting ||
                              loyaltyData.redeemPoint <= 0 ||
                              loyaltyData.redeemPoint !==
                                Number(values.redeemPoint)
                            }
                            variant="contained"
                            color="primary"
                            fullWidth
                          >
                            {isSubmitting ? (
                              <CircularProgress size={24} />
                            ) : (
                              <>{t('loyalty.convertToStoreCredit')}</>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Grid>
      </Grid>
      <Dialog open={showDialog} onClose={closeDialog} maxWidth="xs" fullWidth>
        <DialogTitle>
          {t('loyalty.yourStoreCreditFor')}{' '}
          {t('cardValue', {
            amount: storeCredit.amount || 0,
            formatParams: {
              amount: { currency },
            },
          })}
          . {t('loyalty.weHaveEmailedYouACopyAsWell')}
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold' }}
              color="textSecondary"
            >
              REDEMPTION CODE
            </Typography>
            <Typography
              variant="h5"
              bold
              style={{ color: '#8A6E23', fontWeight: 'bold', marginTop: 20 }}
            >
              {storeCredit.code?.replace(/(.{4})(?!$)/g, '$1  ')}
            </Typography>
            <Box px={4}>
              <img
                src={`https://orderqrcodes.s3.us-east-2.amazonaws.com/${storeCredit.code}`}
                alt={storeCredit.code}
                style={{ width: '100%', objectFit: 'contain' }}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LoyaltyRedeemForm;
