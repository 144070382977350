/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { FormHelperText, Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import CustomInputField from './CustomInputField';
import { countries } from '../../utils/constants';

const CountrySelect = ({
  value,
  onChange,
  countryField = 'countryCode',
  phoneField = 'localPhone',
  errorField = 'phone',
  label = '',
  ...rest
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched } = useFormikContext();

  // Set default values if they don't exist
  const countryCode = values[countryField];
  const localPhone = values[phoneField];

  const onChangeCountryCode = (event) => {
    const phoneNumber =
      countries.find((c) => c.code === event.target.value)?.number +
      values[phoneField];
    setFieldValue(countryField, event.target.value);
    onChange(phoneNumber);
  };

  const onChangeLocalPhone = (event) => {
    const phoneNumber =
      countries.find((c) => c.code === values[countryField])?.number +
      event.target.value;
    onChange(phoneNumber);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Field
          component={FormikTextField}
          fullWidth
          select
          {...rest}
          value={countryCode}
          name={countryField}
          variant="outlined"
          onChange={onChangeCountryCode}
        >
          <MenuItem value={undefined}>---</MenuItem>
          {countries.map(({ name, number, code }) => (
            <MenuItem key={code} value={code}>
              {number} {name}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={7}>
        <CustomInputField
          customInput={Field}
          component={FormikTextField}
          trim
          fullWidth
          required
          label={label || t('yourPhoneNumber')}
          name={phoneField}
          variant="outlined"
          value={localPhone}
          onChange={onChangeLocalPhone}
        />
        {!errors[phoneField] && errors[errorField] && touched[phoneField] && (
          <FormHelperText error>{errors[errorField]}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  countryField: PropTypes.string,
  phoneField: PropTypes.string,
  errorField: PropTypes.string,
  label: PropTypes.string,
};

export default CountrySelect;
