const countries = [
  { name: 'USA', number: '+1', code: 'US' },
  { name: 'Canada', number: '+1', code: 'CA' },
  { name: 'Argentina', number: '+54', code: 'AR' },
  { name: 'Australia', number: '+61', code: 'AU' },
  { name: 'Austria', number: '+43', code: 'AT' },
  { name: 'Brazil', number: '+55', code: 'BR' },
  { name: 'France', number: '+33', code: 'FR' },
  { name: 'Germany', number: '+49', code: 'DE' },
  { name: 'Ireland', number: '+353', code: 'IE' },
  { name: 'Japan', number: '+81', code: 'JP' },
  { name: 'Mexico', number: '+52', code: 'MX' },
  { name: 'Netherlands', number: '+31', code: 'NL' },
  { name: 'New Zealand', number: '+64', code: 'NZ' },
  { name: 'Spain', number: '+34', code: 'ES' },
  { name: 'United Kingdom', number: '+44', code: 'GB' },
];

const listOfMonths = [
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];

// eslint-disable-next-line import/prefer-default-export
export { countries, listOfMonths };
