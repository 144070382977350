/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

import { Skeleton } from '@material-ui/lab';

import ShowMoreText from 'react-show-more-text';
import { isEmpty } from 'lodash/fp';
import { alert, loyalties, organizations } from '../../../state';
import CustomInputField from '../../custom/CustomInputField';
import { CustomImgCard } from '../../custom';
import LoyaltyRegisterSchema from './LoyaltyRegisterSchema';
import PhoneInput from '../../custom/PhoneInput';
import { countries } from '../../../utils/constants';
import ConfirmationDialog from '../../custom/ConfirmationDialog';

const LoyaltyRegisterForm = ({ openAlert, routeId }) => {
  const { t } = useTranslation();
  const loyaltyProgram = useSelector(loyalties.selectors.selectProgram);
  const isLoading = useSelector(loyalties.selectors.selectIsLoading);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const listOfMonths = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];

  const [isConfirm, setIsConfirm] = useState(false);

  const [expand, setExpand] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const toggleExpand = () => setExpand(!expand);

  useEffect(() => {
    if (loyaltyProgram?.country) {
      const country = countries.find((c) => c.code === loyaltyProgram.country);
      if (country) {
        formikRef?.current?.setFieldValue('countryCode', country.code);
      }
    }
  }, [loyaltyProgram]);

  return (
    <Box
      borderRadius={20}
      border="1px solid #ccc"
      p={5}
      mb={4}
      bgcolor={loyaltyProgram.brandColors?.formColor || '#fff'}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box py={2}>
                <Typography variant="h4" align="center">
                  Loyalty Program Sign Up
                </Typography>
              </Box>
              <Box width={270} alignItems="center" margin="auto">
                <CustomImgCard
                  isLoading={isLoading}
                  name={loyaltyProgram.businessName}
                  imageUrl={loyaltyProgram.imageUrl}
                />
              </Box>
              <Box py={1}>
                <Typography variant="h5" align="center">
                  {loyaltyProgram.programName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <Box>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Box>
            ) : (
              <Formik
                innerRef={formikRef}
                validationSchema={() => LoyaltyRegisterSchema()}
                initialValues={{
                  programId: routeId,
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: '',
                  birthMonth: '',
                  localPhone: '',
                  countryCode: 'US',
                }}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true);
                  const response = await dispatch(
                    loyalties.actions.register({
                      ...values,
                    })
                  );

                  if (response.error) {
                    openAlert({
                      message:
                        response.payload?.message ||
                        t('unableCompleteRegistration'),
                      severity: 'error',
                    });
                  } else if (response.payload) {
                    setIsConfirm(true);
                  }
                  actions.setSubmitting(false);
                }}
              >
                {({ handleSubmit, setFieldValue, isSubmitting, values }) => {
                  return (
                    <Form margin="dense" size="small">
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                      >
                        <Grid item xs={12}>
                          <Box pb={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="flex-start"
                              spacing={2}
                            >
                              <Grid item xs={12} md={6}>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  capitalize
                                  fullWidth
                                  required
                                  label={t('yourFirstName')}
                                  name="firstName"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  capitalize
                                  fullWidth
                                  required
                                  label={t('yourLastName')}
                                  name="lastName"
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Box pb={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="flex-start"
                              spacing={2}
                            >
                              <Grid item xs={12} md={6}>
                                <PhoneInput
                                  value={values.countryCode}
                                  onChange={(val) => {
                                    setFieldValue('phone', val);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  trim
                                  fullWidth
                                  required
                                  label={t('yourEmail')}
                                  name="email"
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Grid item md={6} xs={12}>
                          <Box pb={2}>
                            <Field
                              component={FormikTextField}
                              label={t('birthMonth')}
                              select
                              name="birthMonth"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            >
                              {listOfMonths.map((month) => (
                                <MenuItem
                                  key={month.value}
                                  value={month.value}
                                  onClick={() =>
                                    setFieldValue('birthMonth', month.value)
                                  }
                                >
                                  {month.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                      {loyaltyProgram.description ? (
                        <Box py={2} mb={2}>
                          <ShowMoreText
                            lines={2}
                            more={t('showMore')}
                            less={t('showLess')}
                            onClick={toggleExpand}
                            expanded={false}
                          >
                            {loyaltyProgram.description}
                          </ShowMoreText>
                        </Box>
                      ) : null}
                      {!isEmpty(loyaltyProgram.terms) && (
                        <Grid item xs={12}>
                          <Box pt={1}>
                            <Typography
                              display="block"
                              align="center"
                              variant="body2"
                            >
                              <Link
                                component="button"
                                underline="hover"
                                style={{ color: 'blue' }}
                                onClick={handleOpen}
                              >
                                {t('termsAndConditions')}
                              </Link>
                            </Typography>
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              scroll="paper"
                              aria-labelledby="terms and conditions"
                              aria-describedby="terms and conditions"
                              fullWidth
                              maxWidth="sm"
                              BackdropProps={{
                                timeout: 500,
                              }}
                            >
                              <DialogTitle>
                                {t('termsAndConditions')}
                              </DialogTitle>
                              <DialogContent dividers>
                                <DialogContentText
                                  tabIndex={-1}
                                  component="div"
                                >
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    {loyaltyProgram.terms}
                                  </Typography>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                  {t('iAgree')}
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                        </Grid>
                      )}
                      <Box my={3}>
                        <Grid container justifyContent="center">
                          <Grid
                            container
                            item
                            md={4}
                            xs={8}
                            alignItems="center"
                          >
                            <Button
                              onClick={handleSubmit}
                              size="large"
                              disabled={isSubmitting}
                              variant="contained"
                              color="primary"
                              fullWidth
                            >
                              {isSubmitting ? (
                                <CircularProgress size={24} />
                              ) : (
                                <>Sign Up</>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        isConfirmation={false}
        title="Success!"
        content={t('loyalty.successSignUp')}
        actionOk={() => {
          setTimeout(() => window.location.reload(), 100);
        }}
      >
        <div style={{ color: '#000', whiteSpace: 'break-spaces' }}>
          <p>
            <Typography>{t('loyalty.successSignUp')}</Typography>
          </p>
          <p>
            <Typography
              style={{
                fontStyle: 'italic',
              }}
              variant="span"
            >
              {t('loyalty.successNote')}
            </Typography>
          </p>
          <Typography>{t('thankYou')}!</Typography>
        </div>
      </ConfirmationDialog>
    </Box>
  );
};

LoyaltyRegisterForm.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  openAlert: PropTypes.func.isRequired,
  routeId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoyaltyRegisterForm);
