import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField, TextField as FormikTextField } from 'formik-material-ui';

import CustomInputField from '../../custom/CustomInputField';
import PhoneInput from '../../custom/PhoneInput';

const MembershipRecipientForm = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const charLimit = 300;

  return (
    <Grid item xs={12}>
      <Box pb={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <CustomInputField
              customInput={Field}
              component={FormikTextField}
              capitalize
              fullWidth
              required
              label={t('recipientFirstName')}
              name="recipientFirstName"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInputField
              customInput={Field}
              component={FormikTextField}
              capitalize
              fullWidth
              required
              label={t('recipientLastName')}
              name="recipientLastName"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
      <Box pb={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <PhoneInput
              value={values.recipientCountryCode}
              countryField="recipientCountryCode"
              phoneField="recipientLocalPhone"
              errorField="recipientPhone"
              label={t('membership.gift.phone')}
              onChange={(val) => {
                setFieldValue('recipientPhone', val);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInputField
              customInput={Field}
              component={FormikTextField}
              trim
              fullWidth
              required
              label={t('recipientEmail')}
              name="recipientEmail"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Box pb={2}>
              <Grid container item xs={12}>
                <Field
                  component={TextField}
                  fullWidth
                  label={t('personalMessage')}
                  name="personalMessage"
                  variant="outlined"
                  multiline
                  rows={4}
                  inputProps={{
                    maxLength: charLimit,
                  }}
                  helperText={`${values.personalMessage.length}/${charLimit}`}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default MembershipRecipientForm;
