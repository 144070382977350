import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import { Button } from 'gatsby-theme-material-ui';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px',
    background: 'white',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderWidth: '1px',
    borderColor: '#cccccc',
    borderStyle: 'solid',
  },
}));

const TooltipComponent = ({ url }) => {
  const classes = useStyles();
  const [preview, setPreview] = React.useState(false);

  const Popup = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 650,
      padding: 0,
      backgroundColor: 'white !important',
    },
  });

  const renderImagePreview = () => {
    return (
      <Dialog
        open={preview}
        onClose={() => setPreview(false)}
        scroll="paper"
        aria-labelledby="terms and conditions"
        aria-describedby="terms and conditions"
        BackdropProps={{
          timeout: 500,
        }}
        maxWidth="lg"
      >
        <DialogTitle>Seat Location / View</DialogTitle>
        <DialogContent Boxiders>
          <DialogContentText tabIndex={-1} component="Box">
            <img
              width="100%"
              height={450}
              src={url}
              alt="seat location view"
              style={{ objectFit: 'contain' }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreview(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Popup
        interactive
        placement="right"
        arrow
        leaveDelay={100}
        title={
          <Grid item xs={12} className={classes.container}>
            <Box onClick={() => setPreview(true)}>
              <Typography
                variant="subtitle1"
                style={{
                  color: '#3c3c3c',
                  padding: 5,
                }}
              >
                Seat Location / View
              </Typography>
              <img
                width="100%"
                height={450}
                src={url}
                alt="seat location view"
                style={{ objectFit: 'contain' }}
              />
            </Box>
          </Grid>
        }
      >
        <InfoOutlinedIcon
          fontSize="small"
          style={{
            cursor: 'pointer',
            width: '15px',
          }}
        />
      </Popup>
      {renderImagePreview()}
    </>
  );
};
TooltipComponent.propTypes = {
  url: PropTypes.string.isRequired,
};

export default TooltipComponent;
