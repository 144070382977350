import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const TermsDialog = ({ open, handleClose, terms, t }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="terms-and-conditions"
      aria-describedby="terms-and-conditions"
      fullWidth
      maxWidth="sm"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>{t('termsAndConditions')}</DialogTitle>
      <DialogContent>
        <DialogContentText tabIndex={-1} component="div">
          <Typography
            variant="body2"
            component="div"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {terms}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('iAgree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TermsDialog.propTypes = {
  terms: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default TermsDialog;
