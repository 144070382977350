import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useLocation } from '@reach/router';
import TicketCard from './Components/TicketCard';
import EventsCalendar from './Components/EventsCalendar';
import getCurrentRoute from '../../../utils/getCurrentRoute';

const Events = ({ events, brandColors, ticketSetup }) => {
  const location = useLocation();
  const currentRoute = getCurrentRoute(location.pathname);
  const useStyles = makeStyles(() => ({
    fsBackground: {
      background: `${brandColors?.backgroundColor || '#e4f9fc'}`,
      minHeight: '170px',
      maxHeight: '170px',
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: -1,
    },
    fontGilmer: {
      fontFamily: 'gilmer-bold',
    },
    fontNarcost: {
      fontFamily: 'narcost-regular',
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation();

  const [eventGroups, setEventGroups] = useState([]);

  useEffect(() => {
    const groupedEvents = Object.values(events)
      .flat()
      .reduce((acc, event) => {
        const monthYear = moment(`${event.startTime}Z`).format('MMMM YYYY');
        if (!acc[monthYear]) acc[monthYear] = [];
        acc[monthYear].push(event);
        return acc;
      }, {});
    setEventGroups(groupedEvents);
  }, [events]);
  return (
    <>
      <div className={classes.fsBackground} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        background={brandColors?.backgroundColor}
      >
        <Grid container item>
          <Grid item style={{ marginBottom: 48 }}>
            <Typography
              variant="h3"
              className={classes.fontNarcost}
              style={{ marginTop: 32, marginBottom: 32, fontSize: 40 }}
            >
              {ticketSetup?.title
                ? ticketSetup?.title
                : t('ticket.eventAndConcert')}
            </Typography>
            <Typography variant="h5" className={classes.fontGilmer}>
              {ticketSetup?.subTitle
                ? ticketSetup?.subTitle
                : t('ticket.ticketOnSale')}
            </Typography>
          </Grid>

          {currentRoute === 'calendar' && (
            <Grid item container spacing={2} style={{ marginBottom: 100 }}>
              <Grid item md={12} sm={12} xs={12}>
                <EventsCalendar eventList={Object.values(events).flat()} />
              </Grid>
            </Grid>
          )}

          {currentRoute !== 'calendar' &&
            Object.entries(eventGroups).map(([monthYear, eventList]) => (
              <Grid item container spacing={2} style={{ marginBottom: 16 }}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    variant="h5"
                    mb={2}
                    className={classes.fontGilmer}
                  >
                    {monthYear}
                  </Typography>
                </Grid>
                {eventList.map((item) => (
                  <Grid item md={3} sm={6} xs={12} key={item.id}>
                    <TicketCard eventInfo={item} />
                  </Grid>
                ))}
                <Divider
                  style={{ width: '100%', marginTop: 32, marginBottom: 16 }}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

Events.propTypes = {
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandColors: PropTypes.objectOf(PropTypes.any).isRequired,
  ticketSetup: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Events;
