/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  MenuItem,
  Button,
  CircularProgress,
  FormHelperText,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TableCell as MuiTableCell,
  Link,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import moment from 'moment/moment';
import { Stack } from '@mui/material';
import i18n from 'i18next';
import { isEmpty } from 'lodash';
import { CustomImgCard } from '../../custom';
import { alert, businesses, tickets } from '../../../state';
import UserInfoForm from './UserInfoForm';
import UserInfoSchema from './validation';
import TermsDialog from './Components/TicketTerm';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0 0 10px 0',
  },
})(MuiTableCell);
// eslint-disable-next-line react/prop-types
const TicketFreeInfoSelection = ({ changeStep }) => {
  const business = useSelector(businesses.selectors.selectBusiness);
  const useStyles = makeStyles(() => ({
    fsBackground: {
      background: business?.brandColors?.backgroundColor || '#e4f9fc',
      minHeight: '170px',
      maxHeight: '170px',
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: -1,
    },
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: business.brandColors?.textColor,
      },
    },
    fontGilmerBold: {
      fontFamily: 'gilmer-bold',
    },
    fontNarcost: {
      fontFamily: 'narcost-regular',
    },
    fontGilmer: {
      fontFamily: 'gilmer',
    },
    fontTicket: {
      fontSize: '0.95rem',
      position: 'relative',
    },
    smallText: {
      position: 'absolute',
      fontSize: '0.8rem',
      bottom: '-18px',
      right: '8px',
    },
  }));
  const classes = useStyles();
  const formikRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));
  const ticketEvent = useSelector(tickets.selectors.selectTicketEvent);
  const isLoading = useSelector(tickets.selectors.selectIsLoading);
  const ticketSetup = useSelector(tickets.selectors.selectTicketSetup);

  useEffect(() => {
    dispatch(tickets.actions.getTicketSetup(business.id));
  }, [business.id]);

  const { name, imageUrl, tagLine, startTime, title, freeEvent } = ticketEvent;
  const currency = business.currency || 'USD';
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setFormData = (payload) =>
    dispatch(tickets.actions.setFormData(payload));

  const renderTerms = () => {
    return (
      <Grid item xs={12}>
        <Box pt={1}>
          <Typography display="block" variant="body2">
            <Link
              component="button"
              underline="hover"
              style={{ color: 'blue' }}
              onClick={handleOpen}
            >
              {t('termsAndConditions')}
            </Link>
          </Typography>
          <TermsDialog
            open={open}
            handleClose={handleClose}
            terms={ticketSetup?.terms}
            t={t}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      {/* <div className={classes.fsBackground} /> */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          marginTop: 40,
        }}
        className={classes.textColor}
      >
        <Grid
          container
          item
          xs={12}
          md={9}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          style={{
            backgroundColor: business?.brandColors?.formColor || '#eee',
          }}
        >
          <Grid item md={5} xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h3" className={classes.fontNarcost}>
                {title}
              </Typography>
              <Typography
                variant="h6"
                className={[classes.mb2, classes.fontGilmerBold]}
              >
                {tagLine}
              </Typography>
              <Typography
                variant="body1"
                className={[classes.mb2, classes.fontGilmer]}
              >
                {moment(`${startTime}Z`).format('ddd, MMM D, YYYY h:mm A')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={7} xs={12}>
            <CustomImgCard
              isLoading={isLoading}
              name={name}
              imageUrl={imageUrl}
              square
            />
          </Grid>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        validationSchema={UserInfoSchema(ticketSetup)}
        initialValues={{
          businessId: ticketEvent.businessId || '',
          id: ticketEvent.id || '',
          email: '',
          firstName: '',
          lastName: '',
          tickets:
            ticketEvent.ticketPricing?.map((p) => ({
              ...p,
              ticketPricingId: p.id,
            })) || [],
          totalTicket: 0,
          tax: 0,
          fees: 0,
          taxAmount: 0,
          feesAmount: 0,
          totalAmount: 0,
          currency: currency || 'USD',
          couponCode: '',
          couponDiscount: 0,
          couponDetail: {},
          freeEvent: {
            quantity: ticketEvent.seatingRemain,
            maxPerOrder: 6,
            minPerOrder: 1,
            amount: 0,
            purchaseQuantity: 0,
          },
          countryCode: 'US',
          phone: '',
          address: '',
          city: '',
          state: '',
          zipcode: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          setFormData(values);
          const { businessId, id, email, firstName, lastName } = values;
          const data = {
            businessId,
            id,
            email,
            firstName,
            lastName,
            purchaseQuantity: values.freeEvent.purchaseQuantity,
            timezone: new Date().getTimezoneOffset(),
            culture: i18n.language,
          };
          const res = await dispatch(tickets.actions.submitFreeEvent(data));
          if (res.error && res.error?.message) {
            openAlert({
              message: res.error?.message,
              severity: 'error',
            });
            return;
          }
          if (res && res.payload) {
            setFormData({ ...res.payload });
            changeStep(3);
          } else {
            openAlert({
              message: res.payload?.message || 'Something went wrong...',
              severity: 'error',
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue, errors }) => {
          return (
            <>
              <Grid
                container
                justifyContent="center"
                className={[classes.fontGilmer, classes.textColor]}
              >
                <Grid item xs={12} md={9}>
                  <Box
                    style={{ border: '1px solid #ccc' }}
                    p={4}
                    mt={4}
                    className={classes.fontGilmer}
                  >
                    <Typography variant="h5" className={classes.fontGilmerBold}>
                      {t('ticket.tickets')}
                    </Typography>
                    <br />
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {freeEvent && (
                            <TableRow style={{ fontSize: 16 }}>
                              <TableCell>
                                <Typography
                                  variant="button"
                                  className={classes.fontTicket}
                                >
                                  {title}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ textAlign: 'right' }}>
                                <Typography
                                  variant="button"
                                  className={classes.fontTicket}
                                >
                                  Select quantity
                                </Typography>
                              </TableCell>
                              <TableCell width={20} align="right" />
                              <TableCell width={80} align="right">
                                {values.freeEvent?.quantity !== 0 && (
                                  <TextField
                                    className={classes.fontTicket}
                                    select
                                    value={
                                      values.freeEvent?.purchaseQuantity || ''
                                    }
                                    onChange={(event) => {
                                      setFieldValue(
                                        'totalTicket',
                                        Number(event.target.value)
                                      );
                                      console.log('purchaseQuantity', errors);
                                      setFieldValue(
                                        'freeEvent.purchaseQuantity',
                                        Number(event.target.value)
                                      );
                                    }}
                                    variant="outlined"
                                    style={{ fontSize: 16 }}
                                  >
                                    {Array.from(
                                      {
                                        length:
                                          Math.min(
                                            values.freeEvent?.quantity || 0,
                                            values.freeEvent?.maxPerOrder || 0
                                          ) -
                                          (values.freeEvent?.minPerOrder || 0) +
                                          1,
                                      },
                                      (_, i) =>
                                        (values.freeEvent?.minPerOrder || 0) + i
                                    ).map((i) => (
                                      <MenuItem
                                        key={i}
                                        value={i}
                                        className={classes.fontTicket}
                                      >
                                        {i}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              </TableCell>
                              <TableCell
                                width={20}
                                align="right"
                                className={classes.fontTicket}
                              />
                              <TableCell
                                width={150}
                                align="right"
                                className={classes.fontTicket}
                              >
                                {values.freeEvent?.quantity !== 0 ? null : (
                                  <FormHelperText error>
                                    <Typography
                                      variant="body2"
                                      className={[
                                        classes.fontTicket,
                                        classes.fontGilmerBold,
                                      ]}
                                      style={{ textAlign: 'right' }}
                                    >
                                      {' '}
                                      Sold Out
                                    </Typography>
                                  </FormHelperText>
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            {!isEmpty(ticketSetup.terms) && renderTerms()}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                className={classes.textColor}
              >
                <Grid item xs={12} md={9}>
                  <UserInfoForm changeStep={changeStep} />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || values.freeEvent?.quantity === 0}
                    onClick={handleSubmit}
                    size="large"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('continue')
                    )}
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default TicketFreeInfoSelection;
