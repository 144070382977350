import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Divider } from '@material-ui/core';
import { useParams } from '@reach/router';
import { businesses, tickets } from '../../../state';

import SEO from '../../seo';
import { BusinessHeader, BusinessFooter } from '../../layouts';
import TicketInfoSection from './TicketInfoSection';
import TicketTransactionDetails from './TicketTransactionDetails';
import TicketCheckout from './TicketCheckout';
import TicketFreeInfoSelection from './TicketFreeInfoSelection';

const TicketPurchase = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const eventDetail = useSelector(tickets.selectors.selectTicketEvent);
  const business = useSelector(businesses.selectors.selectBusiness);

  React.useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(tickets.actions.fetchTicket(id));
  }, []);

  React.useEffect(() => {
    if (!eventDetail.businessId) {
      return;
    }
    dispatch(businesses.actions.fetchMerchant(eventDetail.businessId));
  }, [eventDetail]);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { name, logoUrl, website } = business;
  const [step, changeStep] = React.useState(1);

  return (
    <>
      <SEO title={`${name}`} />
      <BusinessHeader
        logoUrl={logoUrl}
        merchantUrl={website}
        merchantPintunaUrl={website}
        bgcolor={business.brandColors?.headerColor || '#eee'}
      />
      <Divider />
      <Container
        bgcolor={business.brandColors?.backgroundColor || '#eee'}
        style={{ minHeight: 'calc(100vh - 133px)' }}
      >
        {step === 1 &&
          (eventDetail?.freeEvent ? (
            <TicketFreeInfoSelection changeStep={changeStep} />
          ) : (
            <TicketInfoSection changeStep={changeStep} />
          ))}
        {step === 2 && <TicketCheckout changeStep={changeStep} />}
        {step === 3 && <TicketTransactionDetails changeStep={changeStep} />}
      </Container>
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </>
  );
};

export default TicketPurchase;
