import React from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import moment from 'moment';
import parse from 'html-react-parser';
import { Stack } from '@mui/material';
import { useLocation } from '@reach/router';
import PageWrap from './Components/PageWrap';
import { businesses, tickets } from '../../../state';
import i18n from '../../../i18n';

const useStyles = makeStyles(() => ({
  fsBackground: {
    background: `#e4f9fc`,
    minHeight: '170px',
    maxHeight: '170px',
    width: '100%',
    position: 'absolute',
    left: 0,
    zIndex: -1,
  },
  mb2: {
    marginBottom: 16,
  },
  fontGilmerBold: {
    fontFamily: 'gilmer-bold',
  },
  fontNarcost: {
    fontFamily: 'narcost-regular',
  },
  fontGilmer: {
    fontFamily: 'gilmer',
  },
  ticketTitle: {
    marginBottom: 16,
    paddingTop: '4%',
  },
}));

const EventDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('event_id');
  const dispatch = useDispatch();
  const business = useSelector(businesses.selectors.selectBusiness);
  const eventDetail = useSelector(tickets.selectors.selectEventDetail);
  const fetchTicket = (payload) =>
    dispatch(tickets.actions.getEventDetail(payload));
  React.useEffect(() => {
    (async () => {
      const res = await fetchTicket(id);
      if (!res) {
        navigate('/');
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!eventDetail.businessId) {
        return;
      }
      dispatch(businesses.actions.fetchMerchant(eventDetail.businessId));
    })();
  }, [eventDetail]);

  const goToTicketPurchase = (eventId) => {
    navigate(`/tickets/events/${eventId}`);
  };

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const transform = (node) => {
    if (node.name === 'oembed') {
      const { url } = node.attribs;
      const videoId = new URL(url).searchParams.get('v');
      const iframeUrl = `https://www.youtube.com/embed/${videoId}`;
      return (
        <div style={{ textAlign: 'center' }}>
          <iframe
            width="560"
            height="315"
            src={iframeUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    return null;
  };

  const { name, isWhitelabel, logoUrl, website } = business;
  return (
    <>
      <PageWrap
        isWhitelabel={isWhitelabel}
        logoUrl={logoUrl}
        website={website}
        name={name || 'Event Detail '}
        maxWidth="md"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ padding: 8 }}
        >
          <Grid container item>
            {eventDetail?.id && (
              <>
                <Grid
                  item
                  container
                  spacing={2}
                  className={classes.ticketTitle}
                >
                  <Grid item md={6} sm={6} xs={12}>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h3" className={classes.fontNarcost}>
                        {eventDetail.title}
                      </Typography>
                      <Typography
                        variant="h6"
                        className={[classes.mb2, classes.fontGilmerBold]}
                      >
                        {eventDetail.tagLine}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={[classes.mb2, classes.fontGilmerBold]}
                      >
                        {moment(`${eventDetail.startTime}Z`).format(
                          'ddd, MMM D, YYYY h:mm A'
                        )}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={[classes.mb2, classes.fontGilmerBold]}
                      >
                        {!eventDetail.ticketVenue?.name
                          ? ''
                          : `${eventDetail.ticketVenue?.name}, ${eventDetail.ticketVenue?.address}, ${eventDetail.ticketVenue?.city}, ${eventDetail.ticketVenue?.state}, ${eventDetail.ticketVenue?.zipcode}`}
                      </Typography>
                      <Box>
                        <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            borderRadius: 20,
                            minWidth: '150px',
                            height: '40px',
                          }}
                          onClick={() =>
                            goToTicketPurchase(
                              eventDetail.id,
                              eventDetail.businessId
                            )
                          }
                        >
                          {eventDetail?.freeEvent
                            ? t('ticket.register')
                            : t('ticket.buyTicket')}
                        </Button>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <img
                      width="100%"
                      src={eventDetail.imageUrl}
                      alt={t('tickets.publish.ticketImage')}
                    />
                  </Grid>
                </Grid>
                <Divider
                  style={{ width: '100%', marginTop: 16, marginBottom: 16 }}
                />
                <Grid item container spacing={2} style={{ marginBottom: 16 }}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      className={[classes.fontGilmer]}
                      style={{ fontSize: 16 }}
                    >
                      {parse(eventDetail.description, { replace: transform })}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </PageWrap>
    </>
  );
};

export default EventDetail;
