import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';

import { businesses, checkCardBalance } from '../../../state';
import CheckCardBalanceForm from './CheckCardBalanceForm';
import SEO from '../../seo';
import { Header, Content, BusinessHeader, BusinessFooter } from '../../layouts';
import CardInfoSection from '../CardAddFunds/CardInfoSection';

const CheckCardBalance = ({ location, ...nav }) => {
  const { cardNumber, id: routeId } = nav;
  const { i18n } = useTranslation();
  const business = useSelector(businesses.selectors.selectBusiness);
  const useStyles = makeStyles({
    fsBackground: {
      background: business?.brandColors?.backgroundColor || '#e4f9fc',
      minHeight: '170px',
      maxHeight: '170px',
      width: '100%',
      position: 'absolute',
      left: 0,
      // zIndex: -1,
    },
    checkBalance: {
      position: 'absolute',
      left: '150%',
      top: '-11%',
      width: '200px',
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    textColor: {
      '& *:not(.Mui-error):not(button):not(button span):not(.Mui-selected span *)': {
        color: business.brandColors?.textColor,
      },
    },
  });
  const classes = useStyles();
  const { isWhitelabel, logoUrl, website, partner } = business;
  const dispatch = useDispatch();
  const setCardDetails = (payload) =>
    dispatch(checkCardBalance.actions.setCardDetails(payload));
  const checkGiftCardConfigBalance = (id) =>
    dispatch(businesses.actions.checkGiftCardConfigBalance(id));
  React.useEffect(() => {
    (async () => {
      await checkGiftCardConfigBalance(routeId).then(({ error }) => {
        // TODO create dropdown notification
        if (!isEmpty(error) && routeId) {
          navigate(`/card/${routeId}`);
        }
      });
    })();
  }, []);

  React.useEffect(() => {
    if (business?.culture) {
      i18n.changeLanguage(business.culture);
    }
    setCardDetails(business);
  }, [business]);

  const merchantPintunaUrl = `/card/${routeId}`;
  return (
    <Box className={classes.textColor}>
      <SEO title="Check Card Balance" />
      {isWhitelabel ? (
        <BusinessHeader
          logoUrl={logoUrl}
          merchantUrl={website}
          merchantPintunaUrl={merchantPintunaUrl}
          bgcolor={business.brandColors?.headerColor}
        />
      ) : (
        <Header logo bgcolor={business.brandColors?.headerColor} />
      )}
      <Divider />
      <Box bgcolor={business.brandColors?.formColor}>
        <Content
          style={{ minHeight: !isWhitelabel && 'calc(100vh - 64px - 196px)' }}
        >
          <div className={classes.fsBackground} />
          <CardInfoSection />
          <Box pb={2}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={8}>
                {business?.id && (
                  <CheckCardBalanceForm
                    partner={partner}
                    cardNumberPassed={cardNumber}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Content>
      </Box>
      <BusinessFooter bgcolor={business.brandColors?.footerColor} />
    </Box>
  );
};

CheckCardBalance.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    isWhitelabel: PropTypes.bool,
    website: PropTypes.string,
    imageUrl: PropTypes.string,
    culture: PropTypes.string,
    partner: PropTypes.string,
    brandColors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  checkGiftCardConfigBalance: PropTypes.func.isRequired,
};

export default CheckCardBalance;
