import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

import CustomInputField from '../../custom/CustomInputField';
import PhoneInput from '../../custom/PhoneInput';

const MembershipUserForm = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext();
  return (
    <Grid item xs={12}>
      <Box pb={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <CustomInputField
              customInput={Field}
              component={FormikTextField}
              capitalize
              fullWidth
              required
              label={t('yourFirstName')}
              name="firstName"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInputField
              customInput={Field}
              component={FormikTextField}
              capitalize
              fullWidth
              required
              label={t('yourLastName')}
              name="lastName"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
      <Box pb={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <PhoneInput
              value={values.countryCode}
              onChange={(val) => {
                setFieldValue('phone', val);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInputField
              customInput={Field}
              component={FormikTextField}
              trim
              fullWidth
              required
              label={t('yourEmail')}
              name="email"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default MembershipUserForm;
