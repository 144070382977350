import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, MenuItem, Checkbox } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

import { memberships } from '../../../state';

const MembershipGiftForm = () => {
  const { t } = useTranslation();
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const renewalRecipientTypes = [
    { label: t('membership.gift.recipient'), value: 0 },
    { label: t('membership.gift.myself'), value: 1 },
    { label: t('membership.gift.both'), value: 2 },
  ];
  const {
    values,
    setFieldValue,
    validateField,
    setFieldTouched,
  } = useFormikContext();
  return (
    <Grid item xs={12}>
      <Box p={2} border="1px solid">
        <Grid container>
          <Grid item xs={6}>
            <Field
              type="checkbox"
              component={Checkbox}
              name="isGift"
              color="primary"
              onChange={() => {
                setFieldValue('isGift', !values.isGift);
                setFieldTouched('renewalRecipientType', true);
                validateField('renewalRecipientType');
              }}
              checked={values.isGift}
              disabled={membershipConfig.isGift}
            />
            {t('membership.gift.membershipIsGift')}
          </Grid>
          <Grid item xs={6}>
            <Field
              component={FormikTextField}
              label={t('membership.gift.sendRenewalNoticeTo')}
              select
              name="renewalRecipientType"
              variant="outlined"
              fullWidth
              disabled={!values.isGift}
              required={values.isGift}
            >
              <MenuItem value={undefined}>---</MenuItem>
              {renewalRecipientTypes.map((type) => (
                <MenuItem
                  key={type.value}
                  value={type.value}
                  onClick={() => {
                    setFieldValue('renewalRecipientType', type.value);
                    validateField('renewalRecipientType');
                  }}
                >
                  {type.label}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default MembershipGiftForm;
