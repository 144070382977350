import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { getOr, isEmpty } from 'lodash/fp';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Button,
  Grid,
  Box,
  CircularProgress,
  // MenuItem,
} from '@material-ui/core';

import i18n from 'i18next';
import { checkout, organizations, alert } from '../../../state';
import { handleIOSMsg } from '../../../utils';
import ReCaptcha from '../../CheckboxReCaptcha';

const cloverUrl = process.env.GATSBY_CLOVER_SCRIPT;

const hasCloverScript = () =>
  document.querySelector('script#clover-sdk') !== null;

const hideCloverFooter = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  if (cloverFooter) {
    cloverFooter.style.display = 'none';
  }
};
const appendCloverScript = (setScriptLoaded) => {
  const script = document.createElement('script');
  script.id = 'clover-sdk';
  script.type = 'text/javascript';
  script.src = cloverUrl;
  script.onload = () => {
    setScriptLoaded(true);
  };
  // eslint-disable-next-line no-console
  script.onerror = () => console.error('Failed to Load Clover script!');
  document.body.appendChild(script);
};
const unmountClover = () => {
  const cloverFooter = document.querySelector('.clover-footer');
  const cloverScript = document.querySelector('script#clover-sdk');
  const allCloverFrames = document.querySelectorAll(
    'iframe[title^="INTERMEDIATE"]'
  );
  if (cloverFooter) {
    cloverFooter.remove();
  }
  if (cloverScript) {
    cloverScript.remove();
  }
  if (!isEmpty(allCloverFrames)) {
    Array.from(allCloverFrames).forEach((frame) => frame.parentNode.remove());
  }
};

const unmountFields = () => {
  const allFrames = document.querySelectorAll('#clover-form iframe');
  if (!isEmpty(allFrames)) {
    Array.from(allFrames).forEach((frame) =>
      frame.parentNode.removeChild(frame)
    );
  }
};

let clover;

const CheckoutClover = ({
  formData,
  cloverCheckout,
  getCloverIframeToken,
  organization,
  openAlert,
  isAddFunds,
  routeId,
  // recaptcha,
}) => {
  const { t } = useTranslation();
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const [cloverIsReady, setCloverIsReady] = React.useState(false);
  const [showCaptcha, setShowCaptcha] = React.useState(true);

  const [errors, setErrors] = React.useState({
    CARD_NUMBER: '',
    CARD_DATE: '',
    CARD_CVV: '',
    CARD_POSTAL_CODE: '',
  });
  const { totalAmount, customerPaysAmount } = formData;
  const recaptcha = useRef();

  const cardNumberListner = (payload) => {
    const error = getOr('', ['CARD_NUMBER', 'error'], payload);
    if (errors) {
      setErrors({ ...errors, CARD_NUMBER: error });
    }
  };
  const cardDateListner = (event) => {
    const error = getOr('', ['CARD_DATE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_DATE: error });
    }
  };
  const cardCvvListner = (event) => {
    const error = getOr('', ['CARD_CVV', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_CVV: error });
    }
  };
  const cardPostalCodeListner = (event) => {
    const error = getOr('', ['CARD_POSTAL_CODE', 'error'], event);
    if (errors) {
      setErrors({ ...errors, CARD_POSTAL_CODE: error });
    }
  };

  const openReCaptcha = async () => {
    setShowCaptcha(true);
  };
  const cannotProcessPayments = (payload) => {
    openAlert({
      message: payload?.message || t('weCannotProcessPaymentsPleaseTryLater'),
      severity: 'error',
    });
    openReCaptcha();
  };
  React.useEffect(() => {
    if (!hasCloverScript()) {
      appendCloverScript(setScriptLoaded);
    }
    return () => {
      if (hasCloverScript()) {
        unmountClover();
        setScriptLoaded(false);
        setCloverIsReady(false);
      }
    };
  }, []);
  React.useEffect(() => {
    const { businessId } = formData;
    if (scriptLoaded) {
      // TODO: isLoading State
      const createCloverInstance = async () => {
        if (businessId) {
          const res = await getCloverIframeToken(businessId);
          if (res.payload?.token) {
            const CloverInstance = window.Clover;
            clover = new CloverInstance(res.payload?.token);
            setCloverIsReady(true);
          } else {
            cannotProcessPayments();
          }
        }
      };
      createCloverInstance();
    }
  }, [scriptLoaded]);

  React.useEffect(() => {
    if (cloverIsReady) {
      const elements = clover.elements();
      // Create
      const cardNumber = elements.create('CARD_NUMBER');
      const cardDate = elements.create('CARD_DATE');
      const cardCvv = elements.create('CARD_CVV');
      const cardPostalCode = elements.create('CARD_POSTAL_CODE');
      // Mount
      cardNumber.mount('#card-number');
      cardDate.mount('#card-date');
      cardCvv.mount('#card-cvv');
      cardPostalCode.mount('#postal-code');
      // Add event listener
      cardNumber.addEventListener('blur', cardNumberListner);
      cardDate.addEventListener('blur', cardDateListner);
      cardCvv.addEventListener('blur', cardCvvListner);
      cardPostalCode.addEventListener('blur', cardPostalCodeListner);
      hideCloverFooter();
      return () => {
        cardNumber.removeEventListener('blur', cardNumberListner);
        cardDate.removeEventListener('blur', cardDateListner);
        cardCvv.removeEventListener('blur', cardCvvListner);
        cardPostalCode.removeEventListener('blur', cardPostalCodeListner);
        unmountFields();
      };
    }
    return undefined;
  }, [cloverIsReady]);

  if (!cloverIsReady) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Formik
      initialValues={{
        city: '',
        line1: '',
        state: '',
        postal_code: '',
        token: '',
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        await clover.createToken().then(async (result) => {
          if (result.errors) {
            setErrors(result.errors);
            actions.setSubmitting(false);
            return null;
          }
          const payload = {
            businessId: formData.businessId,
            giftCardConfigId: formData.id ?? formData.giftCardConfigId,
            organizationId: formData.organizationId,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            promoCode: formData.promoCode,
            giftEmail: formData.giftEmail,
            giftFirstName: formData.giftFirstName,
            giftLastName: formData.giftLastName,
            giftMessage: formData.giftMessage,
            isGift: formData.isGift,
            amount: formData.amount,
            giftAmount: formData.giftAmount,
            orgCode: formData.orgCode,
            nonce: result.token,
            code: formData.code,
            culture: i18n.language,
            giftCardImageId: formData.giftCardImageId,
            occasionId: formData.occasionId,
            languageId: formData.languageId,
            giftCardTermId: formData.giftCardTermId,
            token: values.token,
            deliverAt: formData.deliverAt,
          };
          const response = await cloverCheckout(payload);
          const confirmationPath = isAddFunds
            ? `/add-funds/${routeId}/confirmation`
            : `/card/${routeId}/confirmation`;

          console.log(response);
          if (response.error) {
            handleIOSMsg('payment_failed');
            cannotProcessPayments(response.payload);
          } else if (response.payload) {
            if (!isEmpty(organization)) {
              navigate(`${confirmationPath}?org=${organization.id}`, {
                replace: true,
              });
              return null;
            }
            navigate(`${confirmationPath}`, { replace: true });
          } else {
            cannotProcessPayments();
          }
          actions.setSubmitting(false);
          return null;
        });
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue }) => {
        return (
          <Form id="clover-form" onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                {/* Enable if Clover Supports Address Submit */}
                {/* <Box pb={2}>
                  <Field
                    component={FormikTextField}
                    label="Address"
                    name="line1"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box pb={2}>
                  <Grid container display="row" spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        component={FormikTextField}
                        label="City"
                        name="city"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={FormikTextField}
                        label="State"
                        select
                        name="state"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      >
                        {Object.keys(listOfStates).map((state) => (
                          <MenuItem
                            key={state}
                            value={state}
                            onClick={() => setFieldValue('state', state)}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={FormikTextField}
                        label="Zip code"
                        name="postal_code"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            maxLength: '5',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box> */}

                <Box pb={2}>
                  <Grid container item xs={12}>
                    <TextField
                      id="card-number"
                      label={t('cardNumber')}
                      name="ccnumber"
                      variant="outlined"
                      error={!!errors.CARD_NUMBER}
                      helperText={errors.CARD_NUMBER}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: 'div',
                      }}
                    />
                  </Grid>
                </Box>
                <Box pb={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={4}>
                      <TextField
                        id="card-date"
                        label={t('expirationDate')}
                        name="ccexp"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_DATE}
                        helperText={errors.CARD_DATE}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="card-cvv"
                        label={t('cvc')}
                        name="cvc"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_CVV}
                        helperText={errors.CARD_CVV}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="postal-code"
                        label={t('postalCode')}
                        name="postalCode"
                        variant="outlined"
                        fullWidth
                        error={!!errors.CARD_POSTAL_CODE}
                        helperText={errors.CARD_POSTAL_CODE}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: 'div',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item={12}>
                {showCaptcha && (
                  <Box my={2} textAlign="center" className="g-recaptcha">
                    <ReCaptcha
                      onResolved={(token) => {
                        setShowCaptcha(false);
                        setFieldValue('token', token);
                      }}
                      createRef={recaptcha}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  id="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {isSubmitting ? (
                    <CircularProgress size={22} />
                  ) : (
                    t('pay', {
                      amount: customerPaysAmount || totalAmount,
                      currency: formData.currency,
                    })
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

CheckoutClover.propTypes = {
  openAlert: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
    customerPaysAmount: PropTypes.string,
    promoCode: PropTypes.string,
    giftEmail: PropTypes.string,
    giftFirstName: PropTypes.string,
    giftLastName: PropTypes.string,
    giftMessage: PropTypes.string,
    giftAmount: PropTypes.number,
    isGift: PropTypes.bool,
    organizationId: PropTypes.string,
    businessId: PropTypes.string,
    giftCardConfigId: PropTypes.string,
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    orgCode: PropTypes.number,
    amount: PropTypes.number,
    code: PropTypes.string,
    giftCardImageId: PropTypes.string,
    occasionId: PropTypes.string,
    giftCardTermId: PropTypes.string,
    languageId: PropTypes.string,
    currency: PropTypes.string,
    deliverAt: PropTypes.string,
  }).isRequired,
  cloverCheckout: PropTypes.func.isRequired,
  getCloverIframeToken: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  isAddFunds: PropTypes.bool.isRequired,
  routeId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // recaptcha: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formData: checkout.selectors.selectFormData(state),
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  cloverCheckout: (payload) =>
    dispatch(checkout.actions.cloverCheckout(payload)),
  getCloverIframeToken: (payload) =>
    dispatch(checkout.actions.getCloverIframeToken(payload)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutClover);
